import React from 'react'

import TwoColumns from 'components/twoColumns'
import Project from 'components/project'
import SectionHeading from 'components/sectionHeading'

import ScenographicsLogo from 'img/scenographicslogo'

const ScenographicsLink = (
  <a href="https://14richa.github.io/" target = "_blank">Explore My Tech Blog ↗</a>
)

  function Writing() {
  return (
    <TwoColumns
      wide
      leftColumn={<SectionHeading>Writing</SectionHeading>}
      rightColumn={
        <Project
          title="geekGirl"
          abstract="My tech blog where I share experiences, insights, and thoughts on all things tech-related."
          link={ScenographicsLink}
        />
      }
    />
  )
}



export default Writing
