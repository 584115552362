import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import Header from 'components/header'
// import Spirograph from 'components/spirograph'
import heroImage from 'img/Richa_Thames.jpeg'

// const HeroSection = styled.section`
//   // height: 100vh;
//   // max-height: 100vh;
//   // display: flex;
//   // flex-direction: column;
//   // overflow: hidden;
//   // width: 100vw;
//   ${media.sm`
//     height: calc(100vh - 76px);
//   `}
// `

// const Spacer = styled.div`
//   flex: 1 1 0;
// `



// class Hero extends React.Component {




//   render() {
//     return (
//       <HeroSection>

//         <Header />
//         <Spacer />

//       </HeroSection>
//     )
//   }
// }

// export default Hero
// //        <Spirograph speed={0.012} getGearValues={this.getGearValues} />



const HeroSection = styled.section`
  height: 80vh; /* Adjust this value as needed */
  max-height: 80vh; /* Adjust this value as needed */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  background: url(${heroImage}) no-repeat center center;
  background-size: cover;
  ${media.sm`
    height: calc(80vh - 76px); /* Adjust this value as needed */
  `}
`

const Spacer = styled.div`
  flex: 1 1 0;
`

class Hero extends React.Component {
  render() {
    return (
      <HeroSection>
        <Header />
        <Spacer />
      </HeroSection>
    )
  }
}

export default Hero