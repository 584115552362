import React, { Fragment } from 'react'
import Link from 'gatsby-link'

import TwoColumns from 'components/twoColumns'
import SectionHeading from 'components/sectionHeading'
import Project from 'components/project'

import KellerkindLogo from 'img/kellerkindlogo'
import PalettteAppLogo from 'img/palettteapplogo'
import LifeTimeLogo from 'img/lifetimelogo'
import BamLogo from 'img/bamlogo'

const GitFromScratch = <a href = "https://github.com/14Richa/Git-from-scratch" target = "_blank">Github Repo ↗ </a>
const PatientAnalysis = <a href = "https://github.com/14Richa/Patient-Readmission-Analysis" target = "_blank">Github Repo ↗ </a>
const Electra = <a href = "https://github.com/14Richa/ELECTRA" target = "_blank"> Github Repo ↗</a>

const LifeTimeLink = <a href = "https://github.com/14Richa/stream_react" target = "_blank">Github Repo ↗</a>
// const PalettteLink = <a href = "https://videos-react.herokuapp.com" target = "_blank"> Live Demo</a>
const KellerkindLink = <a href = "https://14richa.github.io/2019-03-23/casestudy1" target = "_blank">Read More ↗</a>
const CyberSecurityLink = <a href = "https://github.com/14Richa/AI-Enhanced-Security-for-Detecting-and-Preventing-Cyber-Threats/blob/main/AI_Enhanced_Security_for_Detecting_and_Preventing_Cyber_Threats.pdf" target = "_blank">Read More ↗</a>
// const WebScraperLink = <a href = "https://gfycat.com/definitereasonableafghanhound" target = "_blank">Demo Gif</a>
const SRTLink = <a href = "https://srt-data.netlify.app/data" target = "_blank">Live Demo ↗</a>

const BamLink = <Link to="/bam">Read More</Link>

function Work() {
  return (
    <TwoColumns
      wide
      leftColumn={<SectionHeading>Work</SectionHeading>}
      rightColumn={
        <Fragment>
          <Project
            // logo={LifeTimeLogo()}
            title="Git from scratch"
            abstract="This project showcases various Git commands designed to streamline version control and enhance our development workflow. Each command serves a specific purpose, contributing to efficient codebase management."
            link={GitFromScratch}
          />
          <Project
            // logo={LifeTimeLogo()}
            title="Patient Readmission Analysis"
            abstract="This project focuses on predictive modeling to foresee hospital readmissions of diabetic patients within 30 days post-discharge. By leveraging a dataset spanning a decade (1999-2008) and covering records from 130 US hospitals, the aim is to enhance healthcare management and patient outcomes."
            link={PatientAnalysis}
          />
          <Project
            title="ELECTRA"
            abstract="ELECTRA is a simulation tool designed to optimize the charging process for electric vehicles (EVs) using dynamic programming. The goal is to shift towards smart charging by reducing current usage, maintaining minimum system load, and adjusting charging plans in real-time based on data inputs."
            link={Electra}

          />
          <Project
          title = "Data Visualisation App"
          abstract = "Built a minimal application to visualize Sachin Tendulkar's batting scores across different cuts. Used shards and react to render different components."
          link = {SRTLink}
          />

          <Project
            // logo={KellerkindLogo()}
            title="Web Performance Optimization"
            abstract="A case-study on performance and user-interface improvement for webcompat. Identified bottlenecks in FCP, FMP and TTI rendering with Lighthouse and Pagespeed insights"
            link={KellerkindLink}
          />
          <Project
          title = "AI Enhanced-Security for Detecting and Preventing Cyber Threats "
          abstract = "A report on AI-enhanced security for detecting and preventing cyber threats. It explores into the application of machine learning algorithms to enhance cybersecurity and prevent cyber threats."
          link = {CyberSecurityLink}
          />

          <Project
            // logo={LifeTimeLogo()}
            title="Twitch with a Twist"
            abstract="A streaming web-app connected with streaming software and streamed to a localhost."
            link={LifeTimeLink}
          />
          {/* <Project
            // logo={LifeTimeLogo()}
            title="Web Scraper"
            abstract="A simple webscraper to extract relevant information from NSE website for any tickr. Built using Puppeteer abd Express"
            link={WebScraperLink}
          /> */}

          {/* <Project
            // logo={PalettteAppLogo()}
            title="Minimal Youtube Clone"
            abstract="Minimal video search app without any clutter."
            link={PalettteLink}
          /> */}

          {/*<Project
          title = "Github Finder"
          abstract = "dgbfvksjdf"
          link = {BamLink}
          />*/}
          {/* <Project
            title="Landing Pages for smooth landing"
            abstract="Experimenting different design for different use cases."
            link={BamLink}
          /> */}

        </Fragment>
      }
    />
  )
}

export default Work
