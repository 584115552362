import React, { Fragment } from "react";
import Link from "gatsby-link";
import styled from "styled-components";
import media from "utils/media-queries";

import { color, fontSize } from "styles/theme";

import TwoColumns from "components/twoColumns";
import SectionHeading from "components/sectionHeading";
// import WhenInView from 'components/wheninView'

// const Reveal = styled.div`
//   opacity: ${props => (props.visible ? '1' : '0')};
//   transform: translateY(${props => (props.visible ? '0px' : '30px')});
//   transition: all 1s;
// `

const Big = styled.span`
  font-size: ${fontSize.f6};
  color: ${color.grey900};
  font-weight: 700;
  ${media.sm`
    font-size: ${fontSize.f5};
  `}
`;

function About() {
  return (
    <div>
      <TwoColumns
        leftColumn={<SectionHeading>About</SectionHeading>}
        rightColumn={
          <Fragment>
            <Big>
              Hi, I'm Richa, a seasoned fullstack developer based in&nbsp; London.
              I specialize in crafting clean, elegant, and efficient code to build high-quality web applications.
            </Big>
            <p>
            As a professional with a Master’s in Computer Science from the Creative Computing Institute at the University of the Arts London and a Bachelor’s in Business Administration,
            I blend technical expertise with business insight to deliver impactful solutions.
            My technical skill set spans across frontend and backend development, with proficiency in ReactJS, JavaScript, Python and Django.
            I am experienced in software development, data analysis, and project management. I am dedicated to creating performant,
            scalable web applications that offer seamless user experiences and maintain high standards of code readability and optimization.
            I have been selected for GSoC 2023 with Postman and AsyncAPI, and I was a former Outreachy 2020 participant with Mozilla. My commitment to staying at the forefront of technology drives
            me to continuously expand my skill set and leverage emerging tools and practices.
            </p>
            <p style={{ marginBottom: 0 }}>
            I am also actively involved in various tech meet-ups across London, contributing to a vibrant tech community.
            </p>
          </Fragment>
        }
      />
    </div>
  );
}

export default About;
